<template>
  <div class="container" :class="{'mobile': device == 'mobile'}">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/navigation', query: {mid: level1Menu.pkSerial} }">{{level1Menu.name}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/navigation', query: {mid: level1Menu.pkSerial, cid: level2Menu.pkSerial} }">{{level2Menu.name}}</el-breadcrumb-item>
      <el-breadcrumb-item >内容详情</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
/**
 * 
 * @description 
 * @author Henson
 * @date 2021/03/07 23:34:10
 * @property 
 * @event 
 */

import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      mid: this.$route.query.mid? this.$route.query.mid : '',
      cid: this.$route.query.cid? this.$route.query.cid : '',
    }
  },
  computed: {
    ...mapState({
      menuArray: state => state.menu.menuArray,
      menuTree: state => state.menu.menuTree,
    }),
    // 一级菜单名称
    level1Menu: function() {
      if(!this.menuArray.length) return ''
      return this.menuArray.find(item => item.pkSerial == this.mid)
    },
    // 二级菜单名称
    level2Menu: function() {
      if(!this.menuArray.length) return ''
      return this.menuArray.find(item => item.pkSerial == this.cid)
    },
  },
  watch: {},
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>
<style lang='scss' scoped>
.container {
  padding: 0 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.container.mobile {
  padding: 0 10px;
  padding-top: 20px;
  padding-bottom: 30px;
}
</style>